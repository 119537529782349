var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"notifications-page"},[_c('v-row',{attrs:{"justify":"center"}},[_c('v-col',{attrs:{"md":"6"}},[_c('intract-smart-list',{ref:"notifications-smart-list",attrs:{"disable-search":"","paginated":"","endpoint":_vm.endpoints.notificationsViewSet},on:{"updateAllItemsList":(l) => (_vm.allNotifications = l)},scopedSlots:_vm._u([{key:"list-item",fn:function({ item: notification }){return [_c('v-list-item',{on:{"click":function($event){return _vm.handleClick(notification)}}},[_c('v-list-item-content',[_c('v-list-item-title',{staticClass:"text-wrap"},[_vm._v(_vm._s(notification.title))]),_c('v-list-item-subtitle',[_vm._v(_vm._s(notification.body))]),_c('v-list-item-subtitle',{staticClass:"text-caption"},[(
                    _vm.moment(notification.created).isBefore(
                      _vm.moment().subtract(1, 'days')
                    )
                  )?_c('span',[_vm._v(_vm._s(_vm.moment(notification.created).format("LLL")))]):_c('span',[_vm._v(_vm._s(_vm.moment(notification.created).fromNow()))])])],1),_c('v-list-item-icon',[(notification.feature_name)?_c('v-chip',{attrs:{"small":"","color":"green lighten-4"}},[_vm._v(_vm._s(notification.feature_name))]):_vm._e()],1)],1),_c('v-divider',{staticClass:"mx-4"})]}}])})],1)],1),_c('confirmation-dialog',{attrs:{"title":"Notification","description":_vm.dialog.notification ? _vm.dialog.notification.description : '',"success-text":"OK","failure-text":null,"visible":_vm.dialog.showExpandedNotification},on:{"successCallback":function($event){_vm.dialog.showExpandedNotification = false;
      _vm.dialog.notification = null;},"failureCallback":function($event){_vm.dialog.showExpandedNotification = false;
      _vm.dialog.notification = null;}}},[(_vm.dialog.notification)?_c('div',{staticClass:"mx-6"},[_c('h4',[_vm._v(_vm._s(_vm.dialog.notification.title))]),_c('p',[_vm._v(_vm._s(_vm.dialog.notification.body))]),_c('p',{staticClass:"text-caption"},[_vm._v(" "+_vm._s(_vm.moment(_vm.dialog.notification.created).format("LLL"))+" ")])]):_vm._e()])],1)
}
var staticRenderFns = []

export { render, staticRenderFns }